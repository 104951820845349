import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

import Layout from '../components/layout'
import SEO from "../components/seo"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export default props => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "site.menu.ivanchai" })}
      />
      <div className="container mx-auto text-center mx-8 my-24"><FormattedMessage id="site.wip.text" /><br /> <Link to="/" className="link"><FormattedMessage id="site.wip.link" /> &rarr;</Link></div>
      <div className="w-full h-screen bg-img-11 bg-cover">

      </div>


    </Layout>
  )
}
